import { MS_PER_MINUTE } from "@constants/date";
import { isSameDay, isSameWeek } from "date-fns";
import { FC, PropsWithChildren } from "react";

export type DayGridLayout = ['day' | 'week' | 'month', number]
export type LayoutUnit = 'day' | 'week' | 'month'

export function isLayoutUnit(layoutUnit: unknown): layoutUnit is LayoutUnit {
    return layoutUnit === 'day' || layoutUnit === 'week' || layoutUnit === 'month'
}


export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6

export type CalItemWrapperType = FC<{ item: CalendarItem, children: JSX.Element, edit?: ()=>void }>

export interface NewItemInfo {
    dateStart: number,
    dateEnd?: number, // if all day, doesn't really matter what dateStart and dateEnd are, as dateEnd > dateStart
}

export interface CalendarItem {
    dateStart: number,
    dateEnd?: number, // if all day, doesn't really matter what dateStart and dateEnd are, as dateEnd > dateStart
    name: string,
    id: string,
    colorNum?: number | 'task' | 'event',
    categoryName?: string,
    location?: {
        name: string
    },
    completed?: boolean | number,
    priority?: number,
    [x: string]: any
}

export interface CalendarEvent extends CalendarItem {
    dateEnd: number, // if all day, doesn't really matter what dateStart and dateEnd are, as dateEnd > dateStart
    cron?: string,
    colorNum?: number,
    excludedDays?: Set<string>,
}

export interface CalendarTask extends CalendarItem {
    dateEnd?: undefined,
}

export interface RecurringEvent extends CalendarEvent {
    cron: string,
}

export interface Color { bg: string, txt: string }

export function isCalendarEvent(e: CalendarItem): e is CalendarEvent {
    return e.cron || ('dateEnd' in e && typeof e.dateEnd === 'number')
}

export function isRecurringEvent(e: CalendarItem): e is RecurringEvent {
    return isCalendarEvent(e) && !!e.cron && typeof e.cron === 'string'
}

export function isMultiDayEvent(e: CalendarEvent) {
    return !isSameDay(e.dateStart, e.dateEnd - MS_PER_MINUTE * 10)
}

export function isMultiWeekEvent(e: CalendarEvent, weekStartsOn = 1 as DayOfWeek) {
    return !isSameWeek(e.dateStart, e.dateEnd, { weekStartsOn })
}

export function isCalendarTask(e: CalendarItem): e is CalendarTask {
    return !('dateEnd' in e) && !e.cron
}