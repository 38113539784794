import Checkbox from './Checkbox';
import { useUpdatePlannerItemMutation } from '@/redux/features/api/apiSlice';
import { CalendarEvent } from '@/features/calendars/types';
import { styled } from 'styled-system/jsx'

// ex. checked={displayOptions.hideCompleted}
// onCheckedChange={(checked) => editOptions('hideCompleted', !!checked)}

// TODO: TemplateRecur, after an event has passed, should I create an event for it?, how to I complete an individual event? How do I check which ones are skipped?
// or maybe I can use the excludeEventDay thing, recurring events should automatically be checked after passing, and unchecking skips them? Probably need a confirm, since can't get it back, and a control-z
const EventCheckbox = ({ event }: { event: CalendarEvent }) => {
    const [updateItem] = useUpdatePlannerItemMutation()
    return (
        <Corner>
            <Checkbox icon={'circle'} checked={!!event.completed} onCheckedChange={(checked) => updateItem({
                id: event.id, updates: {
                    set: {
                        completed: checked ? event.dateEnd : 0
                    }
                }
            })} />
        </Corner>
    )
}

export default EventCheckbox

const Corner = styled('div', {
    base: {
        position: "absolute",
        top: 6,
        right: 2,
        zIndex: 1,
        opacity: 0.3
    }
})
