'use client'
import { Unimportant } from '@/components/common'
import { css } from 'styled-system/css';
import { range } from 'lodash'
import React, { useEffect, useMemo, useRef } from 'react'
import { styled } from 'styled-system/jsx'

/**
 *
 * @param showHourBorder honestly not super sure what this is, something to do with not needing a border for the paper layout, TODO: make this prop unnecessary
 * @returns
 */
const HourLabels = ({ showHourBorder = true, disableScroll = false, hideMeridian = false, isCompact }: { showHourBorder?: boolean, disableScroll?: boolean, hideMeridian?: boolean, isCompact?: boolean }) => {

    const scrollToHourRef = useRef(null)
    const hourProps = useMemo(() => range(48).map(halfHour => {
        const isStartOfHour = halfHour % 2 === 0
        const hour = hideMeridian ? halfHour / 2 : ((halfHour / 2) % 12 || 12)
        const meridian = (hideMeridian || isCompact) ? "" : " " + (halfHour < 24 ? "am" : "pm")
        const label = isStartOfHour ? hour + meridian : ''

        const props = {
            ref: isStartOfHour && new Date().getHours() === halfHour / 2 ? scrollToHourRef : null,
            key: "l" + halfHour,
        }

        return { props, label, meridian: halfHour < 24 ? "am" : "pm" }
    }), [hideMeridian, isCompact])

    useEffect(() => {
        // TODO: maybe a scroll animation?
        // @ts-expect-error block: "nearest", inline: "nearest",
        !disableScroll && scrollToHourRef.current?.scrollIntoView({ behavior: "smooth" })
    }, []);

    return (
        <Container color={showHourBorder}>
            {hourProps.map((x, i, arr) => {
                const { props, label, meridian } = x
                return <Label {...props} isLast={showHourBorder && i === arr.length - 1} key={props.key} >
                    {label}
                    {label && isCompact && <Unimportant className={css({ lineHeight: '0.8rem' })}>{meridian}</Unimportant>}
                </Label>
            })}
        </Container>
    )
}

export default HourLabels

const Container = styled('section', {
    variants: {
        color: {
            true: {}
        }
    },
    base: {
        gridArea: 'hours',
        display: 'grid',
        gridTemplateRows: "repeat(48, 2rem)",
        gridAutoFlow: "column"
    }
})

const Label = styled('label', {
    variants: {
        isLast: {
            true: {
                borderBottom: "$borderThin"
            }
        }
    },
    base: {
        boxSizing: 'border-box',
        padding: '0.2rem 0 0 0.2rem',
        flex: '1',
        overflowY: 'visible',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '-50%',
        flexDirection: 'column',
        alignItems: "center"
    }
})
