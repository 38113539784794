import { DatedItem, DatedRes, isTemplateRecurEvent, NewItem } from "src/types"
import { createNewItemPlaceholder, generateItemId, getChildTypeFromId, getDayStringFormat, getTypeFromId } from "@/utils/item"
import { uniq } from "lodash"
import { addDays, isBefore, isSameDay } from "date-fns"
import { useCallback, useMemo } from "react"
import { Pencil1Icon, TrashIcon } from "@radix-ui/react-icons"
import { ContextMenuUnit } from "@/components/common"
import { CalendarItem } from "src/features/calendars/types"
import { mutate as globalMutate } from "swr"
import { castPromiseToItself } from "@/utils/cast"
import { useDeletePlannerItemMutation, useGetDatedItemsQuery, useUpdatePlannerItemMutation } from "@/redux/features/api/apiSlice"
import { useSearchParams } from "next/navigation"
import { usePutPlannerItem } from "../category/usePutPlannerItem"

const getAddDatedItemMutateOptions = (data: NewItem) => ({
    populateCache: false,
    optimisticData: (res: DatedRes | undefined) => {
        if (!res) return [];
        if (data.id) return res.map(x => x.id === data.id ? (data as DatedItem) : x)
        return [...res, createNewItemPlaceholder(data) as DatedItem]
    }
})

// TODO: might want to add categoryNames to datedItem
const useDated = (start: number, end: number, convertTemplateRecurs = false) => {
    const swrKey = `/api/date?start=${start}&end=${end}&convertTemplateRecurs=${convertTemplateRecurs}`
    const { data: beforeFilter, isLoading, error } = useGetDatedItemsQuery({ start, end, convertTemplateRecurs })
    const { putItem } = usePutPlannerItem()
    const [remove] = useDeletePlannerItemMutation({ fixedCacheKey: 'planner-list' })
    const [updateItem] = useUpdatePlannerItemMutation({ fixedCacheKey: 'planner-list' })

    const searchParams = useSearchParams()
    // const { types, categories, sections, days, priorities }
    const types = searchParams?.get('types')
    const categories = searchParams?.get('categories')
    const sections = searchParams?.get('sections')
    const days = searchParams?.get('days')
    const priorities = searchParams?.get('priorities')

    async function add(item: Partial<DatedItem>) {
        if (!item) return;
        const newItem = { ...item, ...(!item.type && !item.id && ('dateEnd' in item && item.dateEnd ? { type: 'event' } : { type: 'task' })) }
        putItem(newItem as NewItem)
        // await mutate(castPromiseToUndefined(fetchPutItem(newItem as NewItem)), {
        //     populateCache: false,
        //     optimisticData: x => {
        //         if (!x) return []
        //         x.push(item as DatedItem) // TODO: make default item
        //         return [...x]
        //     }
        // })
    }

    const data = useMemo(() => {
        return beforeFilter?.filter(item => {
            return checkType(item, types) && checkCategory(item, categories) && checkSection(item, sections) && checkDays(item, days) && checkPriorities(item, priorities)
        })
    }, [beforeFilter, categories, days, priorities, sections, types])

    async function edit(id: string, updates: Partial<DatedItem>) {
        updateItem({ id, updates: { set: updates } })
    }

    const exclude = useCallback(async (id: string, date: number) => {
        if (getTypeFromId(id) !== 'templateRecur' || getChildTypeFromId(id) !== 'event') return
        const val = getDayStringFormat(date)
        return updateItem({ id, updates: { add: { excludedDays: [val] } } })
    }, [updateItem])

    const createItemContextMenu = useCallback((item: DatedItem, calendarItem: CalendarItem, handleEdit: (item?: DatedItem) => void) => {
        const res = [
            { // dependency/coupled in day grid hovercard edit
                label: 'Edit',
                rightSlot: <Pencil1Icon />,
                onSelect: () => handleEdit(item), // TODO should open up a form, or switch tabs if inForm=true
            },
            {
                label: 'Delete',
                rightSlot: <TrashIcon />,
                onSelect: () => remove(item.id),
            },
        ]
        if (isTemplateRecurEvent(item)) {
            res.push(
                {
                    label: 'Exclude',
                    rightSlot: <TrashIcon />,
                    onSelect: () => exclude(item.id, calendarItem.dateStart),
                },
            )
        }
        return res as ContextMenuUnit[]
    }, [remove, exclude])

    return {
        data,
        isLoading: !error && !data,
        isError: !!error,
        add,
        edit,
        remove,
        exclude,
        createItemContextMenu,
        swrKey,
    }
}
export default useDated


function checkCategory(item: DatedItem, categories: unknown) {
    if (!categories || typeof categories !== 'string') return true
    return categories.split(',').some(categoryId => item.category.startsWith(categoryId))
}

function checkSection(item: DatedItem, sections: unknown) {
    if (!sections || typeof sections !== 'string') return true
    return sections.split(',').some(sectionId => item.category.startsWith(sectionId))
}

function checkType(item: DatedItem, types: unknown) {
    if (!types || typeof types !== 'string') return true
    return types.split(',').some(type => getTypeFromId(item.id) === type)
}

function checkPriorities(item: DatedItem, priorities: unknown) {
    if (!priorities || typeof priorities !== 'string') return true
    return priorities.split(',').some(priority => parseInt(priority) === item.priority)
}

function checkDays(item: DatedItem, days: unknown) { // does not handle recurring events
    if (!days || typeof days !== 'string') return true
    const itemDaysOfWeek = [new Date(item.dateStart).getDay()]
    if ('dateEnd' in item && item.dateEnd) {
        let start = addDays(item.dateStart, 1);
        while (isBefore(start, item.dateEnd) || isSameDay(start, item.dateEnd)) {
            // console.log(item, itemDaysOfWeek)
            itemDaysOfWeek.push(start.getDay())
            start = addDays(start, 1)
        }
    }
    return uniq(days.split(',')).map(x => parseInt(x)).some(day => {
        return itemDaysOfWeek.includes(day)
    })
}

export async function handleShallowDatedDataAdd(swrKey: string, data: NewItem, promise: Promise<any>) {
    if (!('dateStart' in data)) return

    await globalMutate(swrKey, castPromiseToItself(promise, swrKey), getAddDatedItemMutateOptions(data))
}