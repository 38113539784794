import ContextMenu from 'src/components/common/ContextMenu'
import React, { useState } from 'react'
import { css } from 'styled-system/css';
import { styled } from 'styled-system/jsx'
import { ContextMenuUnit } from 'src/components/common/ContextMenu'
import { CalendarEvent, CalendarItem } from '../../types'
import { Rnd } from 'react-rnd'
import { cloneDeep, uniqBy } from 'lodash'
import { MS_PER_HALF_HOUR } from '@constants/date'
import { useDroppable } from '@dnd-kit/core'
import { getDayStringFormat } from '@/utils/item'
import WorkBlockItem from './WorkBlockItem'
import { skipToken } from '@reduxjs/toolkit/query'
import { useGetWorkBlockInfoQuery } from '@/redux/features/api/apiSlice'
import EventCheckbox from '@/components/common/EventCheckbox'
import { categoryColorDynamic } from '@/utils/categories'

const Event = ({ rndProps, event, rootEvent, contextMenu, style: style = {}, setIsDragging: setExternalIsDragging, isLong = false, calendarId, ...props }: {
  isLong?: boolean, rndProps: any, event: CalendarEvent,
  contextMenu?: (originalItem: CalendarItem, calendar: CalendarItem) => ContextMenuUnit[],
  style?: any, rootEvent: CalendarEvent,
  setIsDragging?: (isDragging: boolean) => void,
  calendarId: string,
}) => {
  const [isDragging, setIsDragging] = useState<null | number>(null)
  const duration = event.dateEnd ? (event.dateEnd - event.dateStart) : 0;
  const showPrefix = duration > MS_PER_HALF_HOUR * 3 / 2

  const prefix = `${event.categoryName || ''}${(event.categoryName && event.location?.name) ? ' - ' : ''}${event.location?.name || ''}`

  const workBlockId = rootEvent.id + ((event.id !== rootEvent.id || event.cron) ? '#' + getDayStringFormat(event.dateStart) : '')
  const { data: workBlockInfo } = useGetWorkBlockInfoQuery(rootEvent.isWorkBlock ? workBlockId : skipToken)
  // const { data: workBlockInfo } = useSWR<WorkBlockInfo | undefined>(rootEvent.isWorkBlock && `/api/work-block/${workBlockId}`)
  // fetcher('/api/work-block', 'PATCH', { id: over.id, taskIds: [active.id] })

  // for if it is a work block
  // #region work block
  const { setNodeRef, isOver, active } = useDroppable({ // just need workBlock ID + date
    id: workBlockId,
    data: {
      scope: calendarId,
    },
  });
  // #endregion work block

  const isRootEvent = event.id === rootEvent.id

  const isCompleted = isRootEvent ? !!event.completed : Date.now() > event.dateEnd
  // only if the active.id is a task
  return (
    <Rnd
      {...rndProps}
      key={rndProps.key}
      className={css({ overflow: 'hidden', textOverflow: 'ellipsis' })} style={{ zIndex: isLong ? 1 : 2,...(isDragging && { zIndex: 10, boxShadow: 'var(--shadows-strong)' }) }}
      onDragStart={(e) => {
        setIsDragging(Date.now())
        rndProps.onDragStart()
      }}
      onDragStop={(e, d) => {
        if (isDragging && (Date.now() - isDragging) > 200)
          rndProps.onDragStop(e, d)
        setIsDragging(null)
        setExternalIsDragging?.(false) // needed because of conditional rndProps.onDragStop(e, d)
      }}
    >
      <ContextMenu title={event.name} units={contextMenu && contextMenu(rootEvent, cloneDeep(event))} >
        <TimegridItem
          isCompleted={isCompleted}
          ref={rootEvent.isWorkBlock ? setNodeRef : undefined}
          isOver={isOver && typeof active?.id === 'string' && active.id.startsWith('i.task.')}
          // Warning: isWorkBlock not in calendarItem, but in Planda event
          isDragging={!!isDragging}
          isWorkBlock={rootEvent.isWorkBlock}
          isBackground={isLong || (rootEvent.priority !== undefined && rootEvent.priority === 0)}
          style={{ ...style, ...categoryColorDynamic(event.colorNum) }} >
          {/* <div ref={setNodeRef}> */}
          {showPrefix && prefix && <Sub>{prefix}</Sub>}
          <span>{event.name}{(!showPrefix && prefix) ? <Sub className={css({ display: 'inline' })}> {prefix}</Sub> : ''}</span>
          {
            workBlockInfo?.tasks?.length ? <ul className={css({ paddingLeft: 30 })}>
              {
                uniqBy(workBlockInfo.tasks.filter(Boolean), x => x.id).map((x) => <WorkBlockItem workBlockInfo={workBlockInfo} key={x.id} task={x} />)
              }
            </ul> : null
          }
          {isRootEvent && rootEvent.dateEnd < Date.now() && <EventCheckbox event={rootEvent} />}
          {isCompleted && <GrayOverlay />}
        </TimegridItem>
      </ContextMenu>
    </Rnd>
  )
}

export default Event

const Sub = styled('sub', {
  base: {
    textStyle: "unimportantCategory"
  }
})

export const TimegridItem = styled('div', {
  variants: {
    isDragging: {
      true: {
        zIndex: 10,
        boxShadow: "$focus"
      }
    },
    isBackground: {
      true: {
        opacity: 0.4
      }
    },
    isWorkBlock: {
      true: {
        opacity: 0.7
      }
    },
    isOver: {
      true: {
        opacity: 0.5
      }
    },
    isCompleted: {
      true: {
        textDecoration: "line-through",
        opacity: 0.9
      }
    }
  },
  base: {
    boxSizing: "border-box",
    flex: 1,
    padding: 5,
    backgroundColor: "$eventbg",
    color: "$eventtxt",
    border: "$borderThin",
    display: "flex",
    flexDirection: "column",
    gap: 5,
    maxHeight: "100%",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
    height: "100%",
    borderRadius: "5px",
    textOverflow: "ellipsis"
  }
})

export const GrayOverlay = styled('div', {
  base: {
    position: "absolute",
    width: "100%",
    height: "100%",
    marginTop: -5,
    marginLeft: -5,
    opacity: 0.6,
    backgroundColor: "$gray5"
  }
})

export const TimegridItemWrapper = styled('div', {
  base: {
    boxSizing: "border-box",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
    height: "100%"
  }
})