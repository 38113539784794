import { DatedItem, Item } from 'src/types'
import { useMemo } from 'react'
import { CalendarItem } from 'src/features/calendars/types'
import useDated from '@/hooks/main/dated/useDated'
import useCategories from '@/hooks/useCategories'

const useCalendarItems = (start: number, end: number) => {
    const props = useDated(start, end, false)
    const { getCategoryNum, pathToNamesArray, isLoading } = useCategories()

    function convertDatedResToCalendarFormat(res: DatedItem[]): CalendarItem[] {
        return res?.map((x) => {
            const categoryNames = pathToNamesArray(x.category)
            const categoryName = categoryNames && (categoryNames.length > 1 ? categoryNames[1] : undefined)
            const calendarItem: CalendarItem = {
                ...x,
                categoryName,
                // @ts-expect-error
                colorNum: getCategoryNum(x.category) || x.childType || x.type,
            }
            if (x && 'excludedDays' in x) {
                calendarItem.excludedDays = new Set(x.excludedDays)
            }
            return calendarItem
        }) as CalendarItem[]
    }

    const calendarFormat: CalendarItem[] = useMemo(() => (props.data ? convertDatedResToCalendarFormat(props.data) : []), [props.data, isLoading])

    return {
        ...props,
        calendarFormat,
    }
}
export default useCalendarItems
