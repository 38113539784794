import { useCallback, useEffect, useState } from 'react'
import ItemForm from 'src/features/forms/item'
import { DatedItem } from 'src/types'
import { MS_PER_DAY } from '../constants'
import Timegrid, { TimegridProps } from '../timegrid'
import { NewItemInfo } from '../types'
import useCalendarItems from './useCalendarItems'

export interface PlandaTimegridProps extends Omit<TimegridProps, 'data' | 'handleAddItem' | 'handleDoubleClickDate' | 'handleDateChange'> {
    setSWRKey?: (x: string) => void, firstDay: number, style?: any
}

const PlandaTimegrid = ({ firstDay, days, style, setSWRKey, ...props }: PlandaTimegridProps) => {
    const {
        calendarFormat, createItemContextMenu,
        edit: editFn, swrKey, add,
    } = useCalendarItems(firstDay, firstDay + days * MS_PER_DAY)

    useEffect(() => setSWRKey?.(swrKey), [swrKey])

    const [openForm, setOpenForm] = useState(false)
    const [defaultFormValues, setDefaultFormValues] = useState({})

    function handleOpenForm(defaultValues?: NewItemInfo) {
        setDefaultFormValues({ ...defaultValues })
        setOpenForm(true)
    }

    // const add = useCallback((a: Omit<CalendarItem, 'id'>)=>addDated(a), [])
    const edit = useCallback((a: string, b: any) => editFn(a, b), [])

    return (
        <>
            <ItemForm
                open={openForm} setOpen={setOpenForm} defaultType='event' defaultValues={defaultFormValues} />
            <Timegrid
                containerCss={style}
                firstDay={firstDay}
                days={days}
                data={calendarFormat || []}
                handleAddItem={add}
                handleDoubleClickDate={handleOpenForm}
                contextMenu={(item, calendarItem) => createItemContextMenu(item as DatedItem, calendarItem, handleOpenForm,)} // NOTE: difference between Item and Calendar item is name not requried in TemplateRecur
                handleDateChange={edit}
                {...props}
            />
        </>
    )
}

export default PlandaTimegrid